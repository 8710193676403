<script setup lang="ts">
    import { useCompanyQuery } from '@/composables/company';
    import CheckoutSectionCard from '@/checkout/components/common/CheckoutSectionCard.vue';
    import { useI18n } from 'vue-i18n';
    import IconButtonWithOverlayPanel from '@/components/IconButtonWithOverlayPanel.vue';

    const { t } = useI18n();
    const { shouldShowOnAccountOption } = useCompanyQuery();
</script>

<template>
    <CheckoutSectionCard>
        <template #header>{{ t('CHECKOUT.PAYMENT.TITLE') }}</template>
        <template #content>
            <div>{{ t('CHECKOUT.PAYMENT.RENTAL.INFO') }}</div>
            <div class="deposit-info-card">
                <div class="row">
                    <h3 class="text-base-semibold-line-height-auto">{{ t('CHECKOUT.PAYMENT.RENTAL.CARD_TITLE') }}</h3>
                    <IconButtonWithOverlayPanel class="payment-info-icon">
                        <template #icon>
                            <span class="pi pi-info-circle"></span>
                        </template>
                        <template #overlay-panel-content>
                            <div class="panel-content">{{ t('CHECKOUT.PAYMENT.RENTAL.CARD_INFO') }}</div>
                        </template>
                    </IconButtonWithOverlayPanel>
                </div>
                <div class="card-content">{{ t('CHECKOUT.PAYMENT.RENTAL.CARD_DETAIL') }}</div>
                <div v-if="!shouldShowOnAccountOption">{{ t('CHECKOUT.PAYMENT.RENTAL.DEPOSIT_INFO') }}</div>
            </div>
        </template>
    </CheckoutSectionCard>
</template>

<style scoped lang="scss">
    @use 'src/styling/main';

    .deposit-info-card {
        border: 1px solid main.$color-secondary-blue;
        border-radius: 4px;
        padding: main.$spacing-5;
        max-width: 600px;
    }

    .row {
        display: flex;
        justify-content: space-between;
        padding-bottom: main.$spacing-5;
    }

    .card-content {
        padding-right: main.$spacing-6;
    }

    .payment-info-icon {
        color: main.$ctx-primary-color;

        &:hover {
            border: 0 none;
            border-radius: 50%;
            background-color: main.$color-background-bluegray;
        }
    }

    .panel-content {
        max-width: 60ch;
    }
</style>
